<template>
  <div class="row" v-if="checkPer('teachers|stats')">
    <div class="col-12 col-lg-3 g">
      <div class="card" style="border-radius: 10px !important">
        <div class="card-hedaer g">
          <h5>
            <i class="fa fa-check-square"></i>
            عدد الحاضرين
          </h5>
        </div>
        <div class="card-body bg-success" style="border-radius: 0px 0px 10px 10px !important">
          <span style="font-size: 20px" class="text-white">
            {{ reports[1] || reports[2] ? (reports[1] ? reports[1] : 0) + (reports[2] ? reports[2] : 0) : 0 }}
          </span>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-3 g">
      <div class="card" style="border-radius: 10px !important">
        <div class="card-hedaer g">
          <h5>
            <i class="fa fa-clock-o"></i>
            عدد المتأخرين
          </h5>
        </div>
        <div class="card-body bg-warning" style="border-radius: 0px 0px 10px 10px !important">
          <span style="font-size: 20px" class="text-white">
            {{ reports[2] ? reports[2] : 0 }}
          </span>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-3 g">
      <div class="card" style="border-radius: 10px !important">
        <div class="card-hedaer g">
          <h5>
            <i class="fa fa-user-times"></i>
            عدد الغياب
          </h5>
        </div>
        <div class="card-body" style="border-radius: 0px 0px 10px 10px !important; border-top: 1px solid #333">
          <div class="row">
            <div class="col-6 text-center">
              <span style="font-size: 20px" class="text-danger">
              {{ reports[3] ? reports[3] : 0 }} بدون عذر
            </span>
            </div>
            <div class="col-6 text-center">
              <span style="font-size: 20px" class="text-primary">
              {{ reports[5] ? reports[5] : 0 }} بعذر 
            </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-3 g">
      <div class="card" style="border-radius: 10px !important">
        <div class="card-hedaer g">
          <h5>
            <i class="fa fa-sign-out"></i>
            عدد الانصرافات
          </h5>
        </div>
        <div class="card-body bg-secondary" style="border-radius: 0px 0px 10px 10px !important">
          <span style="font-size: 20px" class="text-white">
            {{ leaves }}
          </span>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-4" style="z-index: 9">
      <div id="chartContainer"></div>
    </div>
    <div class="col-12 col-lg-8">
        <div class="d-block d-lg-none">
            <br><br><br><br>
            <br><br><br><br>
            <br><br><br><br>
            <br><br><br><br>
            <br><br><br><br>
            <br>
        </div>
        <div class="card card-body">
            <div class="col-12 table-responsive" style="min-height: auto">
                <table class="table table-hover table-bordered table-sm table-striped">
                    <thead>
                        <th>
                            المعلم
                        </th>
                        <th>
                            الحالة
                        </th>
                        <th>
                            التوقيت
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="report in list" :key="report.number">
                            <td>
                              {{ report.name }}
                            </td>
                            <td>
                              <template v-if="report.status">
                                  <span class="badge badge-secondary" v-if="report.status == 0"
                                  >خارج وقت الدوام</span
                                  >
                                  <span class="badge badge-success" v-if="report.status == 1"
                                  >حاضر</span
                                  >
                                  <span class="badge badge-warning lated" v-if="report.status == 2"
                                  >متأخر</span
                                  >
                                  <span class="badge badge-danger" v-if="report.status == 3"
                                  >غائب</span
                                  >
                                  <span class="badge badge-info" v-if="report.status == 6"
                                  >اجازة</span
                                  >
                                  <span class="badge badge-primary" v-if="report.status == 5"
                                  >غائب بعذر</span
                                  >
                              </template>
                            </td>
                            <td>
                              <span>{{ report.join.time }}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
  </div>
</template>

<script>

import VueApexCharts from 'vue-apexcharts'
const axios = require("axios");
import CanvasJS from '@canvasjs/charts';

export default {
    components: {
        VueApexCharts,
    },
    data(){
        return {
            reports: {},
            list: [],
            leaves: 0,
            user: JSON.parse(localStorage.getItem('user')),
            loaded: false
        }
    },
    created(){
        var g = this;
        var x = setInterval(() => {
            if(g.loaded){
              clearInterval(x)
              var chart = new CanvasJS.Chart("chartContainer", {
              theme: "light2",
              animationEnabled: true,
              title:{
                  text: "احصائيات الغياب"
              },
              styles: {
              width: "100%",
              height: "360px"
              },
              data: [{
                  type: "pie",
                  indexLabel: "{label} (#percent%)",
                  yValueFormatString: "#,##0",
                  toolTipContent: "<span style='\"'color: {color};'\"'>{label}</span> {y} (#percent%)",
                  dataPoints: [
                  { label: "الحضور", y: g.reports[1] },
                  { label: "التأخر", y: g.reports[2] },
                  { label: "الغياب", y: g.reports[3] },
                  { label: "الانصراف", y: g.reports[4] },
                  { label: "غياب بعذر", y: g.reports[4] },
                  ].map(function(a){
                  if(a.y > 0){
                      return a;
                  }else{
                      return false;
                  }
                  }).filter((x)=>{return x;})
              }]
              });
              chart.render();
            }
        }, 100);
        g.getReport()
    },
    methods: {
      getReport(){
        var g = this;
        axios
          .post(api + "/user/teachers/reports/daily", {
            jwt: this.user.jwt,
            date: new Date().toISOString().split("T")[0],
            with_4: true
          })
          .then(function (r) {
            if (r.data.status == 100) {
              var gx = {};
              r.data.response.forEach(function (a) {
                if(!gx[a.status?.toString()]){
                  gx[a.status?.toString()] = 0;
                }
                gx[a.status?.toString()]++
              });
              g.reports = gx;
              g.list = r.data.response
              g.leaves = r.data.leaves
            } else {
              g.$toast({
                component: ToastificationContent,
                props: {
                  title: "حدث خطأ 2",
                  icon: "TimesIcon",
                  variant: "danger",
                },
              });
            }
            g.loaded = true;
          })
          .catch(function () {
            g.loading = false;
            g.table = false;
            g.$toast({
              component: ToastificationContent,
              props: {
                title: "حدث خطأ",
                icon: "TimesIcon",
                variant: "danger",
              },
            });
          });
      }
    }
}
</script>

<style>

</style>
<style>
.canvasjs-chart-credit{
  display: none !important;
}
.favbtn:hover{
  background: #eee !important;
}
</style>